import React from 'react';
import logo from './pme-logo.svg';
import logo_scritta from './paolo_mereghetti.svg';
import './App.css';
import {headerStyle} from './styles/StylesConst';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <img src={logo_scritta} className="App-logo-scritta" alt="logo 2" />
        {/* <p style={headerStyle}>
          mereghetti.eu
        </p> */}
      </header>
    </div>
  );
}

export default App;
